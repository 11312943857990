export async function GetAllRequiredFields() {
  const elements = document.querySelectorAll("[required]")
  const requiredFields = []
  for (const element of elements) {
      requiredFields.push(element.id)
  }
  return requiredFields
}

export async function CheckForm(requiredFields) {
  let requiredFieldsCounter = 0
  let formIsOk = false
  for (const id of requiredFields) {
      const element = document.getElementById(id)
      let hasUrl = null
      if (element) {
        if (element.type === 'file')  hasUrl = (element.parentElement).style.backgroundImage
        if ((element.type === 'text' || element.type === 'textarea') && element.value !== null && element.value !== "") requiredFieldsCounter++
        if (hasUrl !== null && hasUrl !== '') requiredFieldsCounter++
      }
  }
  if (requiredFieldsCounter === requiredFields.length) formIsOk = true
  else formIsOk = false
  return formIsOk
}